// 상단배너
.banner {
  color: white;
  height: 50vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #081d52;
  background-image: linear-gradient(76deg, #081d52 17%, rgba(8, 22, 36, 0.5) 52%, #001427 79%);
  position: relative;
  z-index: 0;
}

.lottie_box {
  width: 100%;
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  @media (max-width: 700px) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.banner_lottie {
  float: right;
  height: 100vh;
  object-fit: cover;
  vertical-align: bottom;
  transition: all 0.4s ease-in-out;

  @media (max-width: 700px) {
    height: 40vh;
    width: 80%;
    float: none;
  }
}

.banner_text_area {
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 55%;
}

.banner_text {
  max-width: 1460px;
  padding-inline: 50px;
  margin-inline: auto;

  h1 {
    font-size: clamp(25px, 3vw, 4.5em);
    word-break: keep-all;
  }
}

// 아코디언 css
.accordion {
  border-top: 1px solid #081d52;
  border-bottom: 1px solid #081d52;
  box-shadow: none;
}

.section {
  max-width: 1460px;
  height: auto;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 5em;
  word-break: keep-all;
}

.section:first-of-type {
  max-width: 1460px;
  height: auto;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 5em;
  padding-block-start: 10em;
  word-break: keep-all;
}

.section:last-of-type {
  max-width: 1460px;
  height: auto;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 5em;
  padding-block-end: 10em;
  word-break: keep-all;
}


.year {
  font-size: clamp(30px, 3vw, 4em);
  margin-bottom: 20px;
}
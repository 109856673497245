@font-face {
	font-family: 'SCoreDream';
	font-style: normal;
	font-weight: 100;
	src: url(./scoredream/SCDream1.otf) format('opentype');
}

@font-face {
	font-family: 'SCoreDream';
	font-style: normal;
	font-weight: 200;
	src: url(./scoredream/SCDream2.otf) format('opentype');
}

@font-face {
	font-family: 'SCoreDream';
	font-style: normal;
	font-weight: 300;
	src: url(./scoredream/SCDream3.otf) format('opentype');
}

@font-face {
	font-family: 'SCoreDream';
	font-style: normal;
	font-weight: 400;
	src: url(./scoredream/SCDream4.otf) format('opentype');
}

@font-face {
	font-family: 'SCoreDream';
	font-style: normal;
	font-weight: 500;
	src: url(./scoredream/SCDream5.otf) format('opentype');
}

@font-face {
	font-family: 'SCoreDream';
	font-style: normal;
	font-weight: 600;
	src: url(./scoredream/SCDream6.otf) format('opentype');
}

@font-face {
	font-family: 'SCoreDream';
	font-style: normal;
	font-weight: 700;
	src: url(./scoredream/SCDream7.otf) format('opentype');
}

@font-face {
	font-family: 'SCoreDream';
	font-style: normal;
	font-weight: 800;
	src: url(./scoredream/SCDream8.otf) format('opentype');
}

@font-face {
	font-family: 'SCoreDream';
	font-style: normal;
	font-weight: 900;
	src: url(./scoredream/SCDream9.otf) format('opentype');
}

/* 
.fade-enter-active {
  transition: opacity .3s ease;
}

.fade-exit-active {
  transition: opacity .3s ease;
}
.fade-enter {
  opacity: 0;
} 
.fade-exit-done {
  opacity: 0;
} */

.fade-enter-active, .fade-exit-active{
  opacity: 0;
  transition: opacity .3s ease;
}

.fade-enter, .fade-exit-done {
  opacity: 0;
}

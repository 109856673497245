.bg_section {
  color: white;
  background-color: #081d52;
  background-image: linear-gradient(76deg, #081d52 17%, rgba(8, 22, 36, 0.5) 52%, #001427 79%);
  z-index: 80;
}
.flexible {
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}
.bg_image {
  position: absolute;
  border-radius: 50%;
  top: 300px;
  // transform: translate(-23vw);  //useEffect로 적용
  height: 120%;
  opacity: 0.7;
  @media (max-width: 990px) {
    left: -10%;
  }
  @media (max-width: 550px) {
    left: -10%;
  }
}


.Achievements_contents {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
}
.Achievements_inner {
  max-width: 1460px;
  margin-inline: auto;
  padding-inline: 50px;
  padding-block: 10em;
}
.Achievements_content {
  list-style-type: none;
  padding-left: 30%;
  margin-top: 10%;
  @media (max-width: 990px) {
    padding-left: 20%;
  }
  @media (max-width: 550px) {
    padding-left: 10%;
  }

  li {
    margin-bottom: 100px;
    font-weight: 600;    
    font-size: clamp(36px, 4vw, 4em);
    word-break: keep-all;    
  }

  li:nth-child(2) {
    padding-left: 10%;
  }
  li:nth-child(3) {
    padding-left: 10%;
  }
  li:nth-child(4) {
    margin-bottom: 0;    
  }
}

.greeting {
  max-width: 1460px;
  min-height: 800px;
  word-break: keep-all;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 10em;
  word-break: keep-all;
  transition: all 0.4s ease-in-out;
  position: relative;

  @media(max-width: 900px) {
    min-height: 900px;
  }

  @media(max-width: 730px) {
    min-height: 1000px;
  }
}

.greeting_text {
  margin-top: 40px;
  z-index: 10;
  position: absolute;
  font-size: clamp(16px, 1.2vw, 1.2em);

  @media(max-width: 900px) {
    position: static;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.56;
  }
}

// 대표님 얼굴과 서명
.sign {
  width: 220px;
  height: 100px;
  left: 50px;
  bottom: 10em;
  position: absolute;
  transition: all 0.4s ease-in-out;

  @media(max-width: 900px) {
    width: 180px;
    height: 80px;
  }

  @media(max-width: 730px) {
    width: 150px;
    height: 60px;
    bottom: 20em;
  }

  @media(max-width: 570px) {
    bottom: 16em;
  }

  @media(max-width: 470px) {
    width: 100px;
    height: 40px;
  }

  @media(max-width: 390px) {
    width: 90px;
    height: 40px;
  }
}

.greeting_lottie {
  opacity: 0.8;
  width: 700px;
  z-index: 0;
  position: absolute;
  right: 50px;
  bottom: 10em;
  transition: all 0.4s ease-in-out;

  @media(max-width: 1460px) {
    width: 550px;
  }

  @media(max-width: 900px) {
    width: 450px;
  }

  @media(max-width: 730px) {
    width: 400px;
  }

  @media(max-width: 570px) {
    width: 300px;
  }

  @media(max-width: 390px) {
    width: 280px;
  }
}
$mainColor: #283c59;
$subColor: rgba(128, 127, 127, 0.8);
$hoverColor: rgb(255, 181, 69);


.hashtag_section {
  margin: 10px 0 10px 0;
  position: relative;
  width: 25%;
  
  // background-color: rgba(236, 184, 184, 0.5);

  @media (max-width: 990px) {
    display: none;
  };

  &::before {
    content: '';
    height: 100%;
    border-right: 1px solid rgb(184, 184, 184);
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.hashtag_title {
  h1 {
    font-size: 24px;
    margin-bottom: 34px;
  }
}


.tags {
  padding-left: 24px;

  li {
    word-break: keep-all;
    color: $mainColor;
    margin: 20px 20px 25px 0;
    font-size: 16px;
    cursor: pointer;

    &::marker {
      content: "# ";
    }

    &:hover {
      color: $hoverColor;
    }
  }
}
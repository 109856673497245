@media (max-width: 768px) {
  .content {
    display: block !important;
  }
  .text_box {
    margin-top: 15%
  }
  .content > img {
    width: 100%;
    display: block;
    margin: auto;
  }
  
}

.text_box_articles {
  font-weight: 600;
  color: var(--fontColor);
}
.text_box > h2 {
  color: var(--fontColor);
  font-size: 1.8em;
  margin-top: 0;
  margin-bottom: 8%;
}
.text_box > p {
  font-size: 1.1em;
}
.text_box {
  flex: 1;
  text-align: center;
  margin-inline: 5%;
}

.content {
  display: flex;
  padding: 16px;
  align-items: center;
  margin-top: 20px;
}
.content > img {
  box-shadow: 5px 5px 10px grey;
}

#Products > p {
  font-size: 1.1em;
  padding-inline: 16px;
}

#Products > h1 {
  font-size: 2.1em;
  margin-block: 0;
  padding: 16px 16px 0 16px;
  
}

#Products {
  position: relative;
  margin: 0 auto;
  max-width: 1100px;
  // text-align: center;
  padding-block: 116px;
  color: black;
}

#Products::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #e6e6e6;
}
@font-face {
	font-family: 'aggro';
	font-style: normal;
	font-weight: 300;
	src: url(./aggro/SB_aggro_M.ttf) format('truetype'), 
        url(./aggro/SB_aggro_OTF_M.otf) format('opentype'),
}

@font-face {
	font-family: 'aggro';
	font-style: normal;
	font-weight: 500;
	src: url(./aggro/SB_aggro_L.ttf) format('truetype'), 
        url(./aggro/SB_aggro_OTF_L.otf) format('opentype'),
}

@font-face {
	font-family: 'aggro';
	font-style: normal;
	font-weight: 700;
	src: url(./aggro/SB_aggro_B.ttf) format('truetype'), 
        url(./aggro/SB_aggro_OTF_B.otf) format('opentype'),
}

$color: #081b4b;

.section_wrap_column {
  max-width: 1460px;
  margin-inline: auto;
  padding: 10em 50px 20em 50px;
}
.labs_content {
  margin-top: 16px;
}
.effect_p {
  margin-bottom: 20px;
  margin-right: 10px;
  cursor: pointer;
  float: right;
  color: $color !important;
  font-size: max(1.0vw, 1.0em) !important;
  font-weight: 600 !important;
	animation: pulse 3s infinite;
  
  &:hover {
    color: rgb(255, 181, 69) !important;
    transition: transform .5s;
    transform: scale(1.05) ;
    transform-origin: center;
  }
  @media (max-width: 1199px) {    
    visibility: hidden;
  }
}
.grid {
  width: calc(100% + 20px) !important;
  // background-color: rgba(135, 206, 235, 0.6);
  margin-left: -10px;
}
.lab_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  background-color: $color;
  height: calc(100% - 80px);
  border-radius: 10px;
  padding: 40px 35px;
  transition: transform .2s ease-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
    transition: transform .2s ease-out;
    box-shadow: 10px 5px 15px rgba(156, 156, 156, 0.5);
  }
}
.lab_card_title {  
  margin-bottom: 60px;

  span {
    color: rgb(146, 213, 240);
  }
  h1 {    
    margin-top: 20px;
    font-size: 1.8em;
    word-break: keep-all;    
  }
  p {
    margin-top: 10px;
    font-size: 1.1em;
    word-break: keep-all;
    color: rgba(156, 156, 156, 0.9)
  }
}
.lab_card_ul {
  padding-left: 20px;
  margin-block: 0;

  li {
    p {
      margin-top: 5px;
      color: tomato
    }
    
    &:first-child {
      margin-bottom: 20px;
    }
  }
}

.effect_p_b {
  visibility: hidden;
  margin-bottom: 20px;
  margin-right: 10px;
  cursor: pointer;
  float: right;
  color: $color !important;
  font-size: max(1.0vw, 1.0em) !important;
  font-weight: 600 !important;
  animation: pulse 3s infinite;

  &:hover {
    color: rgb(255, 181, 69) !important;
    transition: transform .5s;
    transform: scale(1.05);
    transform-origin: center;
  }

  @media (max-width: 1199px) {
    visibility: visible;
  }
}

@keyframes pulse {
	0% {
		transform: scale(0.90);
		// box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	70% {
		transform: scale(1);
		// box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	100% {
		transform: scale(0.90);
		// box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.section_wrap_column {
  max-width: 1460px;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 10em;
}

.grid {
  width: calc(100% + 20px) !important; 
  margin-left: -10px;
  word-break: keep-all;
}
.grid_card {
  display: flex;
  padding-inline: 10px;
  margin-bottom: 50px !important;
  @media (max-width: 1600px) {
    flex-direction: column !important;
    text-align: center;
    align-items: center;
  }
  @media (max-width: 900px) {
    margin-top: 50px;
  }
}

.grid_card_title {
  max-width: 400px;
  padding: 0 20px 0 40px;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  h3 {
    font-size: clamp(17px, 1.5vw, 1.5em);
    margin: 0
  }
  p {
    margin-top: 10px;
    font-size: clamp(16px, 1.2vw, 1.2em);
  }
  @media (max-width: 1600px) {
    padding: 15px 20px 15px 20px;
  }
}

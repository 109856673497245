.contents {
  width: calc(100vw - 270px);
  min-width: 830px;
  position: fixed;
  left: 270px;
  height: 100vh;
  overflow: auto;
  overflow-y: scroll;
}

.horizontal {
  display: flex;
}
// @media (min-width: 768px) {
//   .col {
//     flex: 0 0 100%;
//     max-width: 100%;
//   }
// }

@media (max-width: 1100px) {
  .col_6 {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding: 12px;
    background-color: aqua;
  }
  .row {
    flex-direction: column;
  }
}

.col_6 {  
  max-width: 50%;
  max-height: 400px;
}
.row {
  display: flex;
  flex-grow: 1;
}
.intro {
  max-width: 1460px;
  word-break: keep-all;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 10em;
}

.intro_text {
  font-size: clamp(16px, 1.2vw, 1.2em);
  margin-top: 40px;
  line-height: 1.56;

  p {
    margin-bottom: 20px;
  }
}
.section_wrap {
  max-width: 1460px;
  display: flex;
  align-items: center;
  margin-inline: auto;
  padding: 10em 50px 20em 50px;
}
.rd_contents {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  position: relative;

  @media(max-width: 1200px) {
    flex-direction: column;
  }
}
.rd_title {
  z-index: 10;
  width: 50%;
  margin-block: auto;

  @media(max-width: 1200px) {
    width: 100%;
  }
}
.sub_p {
  p {
    font-size: clamp(17px, 1.05vw, 3em);
    font-weight: 400 !important;
    line-height: 1.54;
    color: rgba(128, 127, 127, 0.8);
    word-break: keep-all;
  }
}


.rd_image {
  width: 65%;
  display: flex;
  aspect-ratio: 1.8;
  z-index: 5;
  position: absolute;
  top: 0;
  right: 0;

  @media(max-width: 1200px) {
    width: 100%;
    margin-top: 100px;
  }
  @media(max-width: 990px) {
    opacity: 0.3;
  }

  img {
    transition: all 1s ease;
    
    @media(max-width: 990px) {
      opacity: 0.28;
      transition: all 1s ease;
    } 
  }
}

// .parallax {
//   width: 100%;
//   border-radius: 10px;
//   background-size: cover;
//   background-position: center;
//   background-attachment: fixed;
//   background-repeat: no-repeat;
//   background-image: url('./Carousel-02.png');
// }


// .rd_bg {
//   width: 100%;
//   height: 120%;
//   position: absolute;
//   left: 10em;
//   // background-color: rgba(208, 217, 223, 0.5);
//   margin-right: -550px;
//   background-color: rgba(0, 255, 0, 0.2);  
//   border-radius: 10px 0px 0px 10px;
// }
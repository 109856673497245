.section_wrap {
  max-width: 1460px;
  align-items: center;
  margin-inline: auto;
  padding: 5em 50px 15em 50px;
}
.articles {
  width: 100%;
  display: flex;
  padding-block: 5em;
}


.category_btn {
  @media (max-width: 990px) {
    display: none;
  }
}
.category_dropBox {
  display: none;
  @media (max-width: 990px) {
    display: block;
  }
}
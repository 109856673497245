.menuItem:hover {
  color: #3b8edb;
  background-color: white !important;
  scale: 1.05;
  transition:  .3s ease;
}
.accounts_wrap img {
  margin: auto 0;
  max-width: 34px;
  aspect-ratio: 1;
  border-radius: 8px;
  // margin-top: 4px;
  margin-right: 8px;
}
.accounts_wrap {
  display: flex;
  align-items: center;
}

.accounts {
  padding-bottom: 24px;
}

.subproperty_item:hover {
  color: #3b8edb;
  background-color: #e1ecfc;
}
.subproperty_item {
  text-decoration: none;
  width: 100%;
  margin: 2px 0;
  padding: 8px 8px ;
  border-radius: 8px;
  margin-left: 15px;
  display: flex;
  color: #1c2738;
  align-items: center;
}

.subproperty {
  margin-left: 20px;
  color: #525963;
  border-left: 2px solid rgb(197, 197, 197);
  display: flex;
}

.icon {
  margin-left: 12px;
  margin-right: 8px;
  z-index: 50;
}

.content_title div:hover {
  background-color: #DEEBFB;
}
.content_title:hover {
  color: #3b8edb;
  background-color: #e1ecfc;
}
.content_title {
  text-decoration: none;
  padding-block: 8px;
  margin-block: 8px;
  z-index: 100;
  color: var(--fontColor);
  display: flex;
  font-size: 17px; 
}

.contents {  
  flex: 1;
  padding-block: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 600;  
}

.title p {
  color: var(--fontColor);
  font-size: 1.4em;
  font-weight: 600;
  margin: 0 0 0 12px;
}

.title img {
  max-width: 34px;
  aspect-ratio: 1;  
}

.title {
  max-height: 30px;
  position: relative;
  padding-block: 20px;
  display: flex;
  align-items: center;
  ::before {
    content:'';
    position: absolute;
    bottom: 0;
    left:0;
    right: 0;
    height: 1.5px;
    background-color: #d3d4d8;
    
  }
}


.sidebar_wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.side_bar {
  display: block;
  background-color: #eef0f5;
  width: 222px;
  min-width: 222px;
  height: 100vh;
  position: fixed;
  padding-inline: 24px;
}
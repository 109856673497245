.section {
  color: white;
  background-color: #081d52;
  background-image: linear-gradient(
    76deg, #081d52 17%, 
    rgba(15, 42, 66, 0.5) 52%, 
    #061c30 70%
  );
}


.main_content {
  max-width: 1460px;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 10em;
  display: flex;
  
  h1 {
    // font-size: clamp(38px, 4vw, 6em);
    font-size: clamp(33px, 2.5vw, 4.2em);
    word-break: keep-all;
  }

  @media (max-width: 1400px) {
    display: block;
  }
}

.section_left {
  width: 25%;
  padding-right: 30px;
  position: relative;
  word-break: keep-all;
  
  p {
    color: rgba(255, 255, 255, 0.6);
    display: inline-block;
    cursor: pointer;
  
    &:hover {
      color: rgb(255, 181, 69);
    }

  }

  @media (max-width: 1400px) {
    display: none;
  }
}
.left {
  width: 25%;
  padding-right: 30px;
  position: relative;
  word-break: keep-all;

  @media (max-width: 1400px) {
    width: 100%;
    padding-right: 0px;
  }
}

.basic_info {
  word-break: keep-all;
  h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

.section_right {
  width: 75%;
  padding-left: 30px;
  word-break: keep-all;

  @media (max-width: 1400px) {
    width: 100%;
    padding-left: 0px;
  }

  p {
    font-size: 1.05em;
    line-height: 1.6;
  }
}


.right {
  width: 75%;
  padding-left: 30px;
  word-break: keep-all;
  
  @media (max-width: 1400px) {
    width: 100%;
    padding-left: 0px;
    margin-top: 200px
  }

  div {
    margin-top: 100px;

    p {
      font-size: 1.05em;
      line-height: 1.6;
    }
  };
}



.bottom {
  padding-bottom: 20em;
}

.back_p {
  cursor: pointer;
  margin-top: 200px;
  float: right;

  &:hover {
    color: rgb(255, 181, 69);
  }

}

.link {
  color: #081d52;
  transition: all 0.5s ease;
  font-weight: bold;


  &:hover {
    color: rgb(255, 181, 69);
    transition: all 0.5s ease;
  }
  
}
.grid_row {
  grid-column-start: 1;
  grid-column-end: 3;
}

.false {
  color: rgba(123, 0, 123, 0.9);
}
.true {
  color: #287b96e6;
}

.card_contents {
  padding: 8px;
  font-size: 0.7em;
  font-weight: 400;
  color: grey;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;
}

.card_title {
  display: flex;
  align-items: center;
  color: #574D5E;
  font-size: .8em;
  p {
    padding-left: 8px;
  }
}

.card_elevation > p {
  font-size: 1.1rem;
  color: #574D5E;
}

.card_elevation {
  margin-block: 20px;
  margin-inline: 8px;
  width: 100%;
  background-color: rgb(253, 253, 253);
  box-shadow: 1px 2px 3px 2px #e0e3e9;
  padding: 12px;
}

.card_list {
  display: flex;
}

.card_outline > p {
  font-size: 1.2em;
}

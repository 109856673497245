.links {
  // background-color: rgba(240, 162, 240, 0.6);
  list-style-type: none;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0;
  padding-left: 0;

  li {
    font-size: 18px;
    padding-inline: 45px;
    display: flex;
    align-items: center;

    span {
      cursor: pointer;
      color: white;
      transition: transform 0.1s ease-out;

      &:hover {
        // color: rgb(226, 157, 77);
        transform: scale(1.1);
        transition: transform 0.1s ease-out;
      }
    }
  }
  @media(max-width: 990px) {
    visibility: hidden;
  }
}

.link_li {
  position: relative;
  display: flex;
}

.sub_link_wrap {
  color: white;
  position: absolute;
  left: 50%;
  top: calc(100px - 20px);
  min-width: 180px;
  padding: 10px 25px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 10px 30px 0 rgba(255,255,255,0.03); 
  background-color: #0e3152;
  transform: translate(-50%, 15px);
  transition: transform .4s, opacity .4s, top .4s;
  opacity: 0;
  z-index: 120;
  overflow: hidden;
  visibility: hidden;
}

.responsive {
  top: calc(68px - 20px);
}

.sub_link_li {
  display: list-item;
  padding-inline: 0 !important;
  margin: 15px 0;

  a {
    font-size: 17px;
    height: auto;
    
    line-height: 1.5;
  }
}

.on {
  opacity: 1 !important;
  visibility: visible !important;
}
.card_outline {
  border: solid 2px #eef1f7 ;
  border-radius: 17px;
  font-size: 1.3em;
  font-weight: 700;
  padding: 16px;
  margin-block: 16px;
  color: #283c59;
}

.card {
  background-color: #eef1f7; 
  border-radius: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3em;
  font-weight: 700;
  padding: 16px;
  margin-block: 16px;
  color: #283c59;
}

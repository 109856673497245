.logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  transition: all .4s;
  z-index: 110;

  span {
    color: white;
  }

  @media (min-width: 1845px) {
    left: calc((100% - 1800px)/2) !important;
  }
}
.logo_inner {
  display: flex;
  align-items: center;  
}

.logo_img {
  height: clamp(40px, 3vw, 46px);
  vertical-align: bottom;
  transition: all .4s;
  @media (max-width: 990px) {
    height: 34px;
    transition: all .4s;
  }
}
.logo_img_scroll {
  height: 38px;
}

.logo_p {
  font-size: clamp(26px, 1.5vw, 30px);
  font-weight: 600;
  margin-left: 12px;
  float: right;
  transition: all .4s;

  @media (max-width: 990px) {
    font-size: 20px;
    margin-left: 5px;
  }
}
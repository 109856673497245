.section {
  color: white;
  height: 100vh;
  background-color: #081d52;
  background-image: linear-gradient(76deg, #081d52 17%, rgba(8, 22, 36, 0.5) 52%, #001427 79%);
  span {
    color: rgba(255, 255, 255, 0.8)
  }
}
.section_main_wrap {
  // background-color: aquamarine;
  position: relative;
  // height: inherit;
}
.main_lottie_box {
  height: inherit;
  position: absolute;
  top: 0;
  right: -200px;
  width: 1300px;
  height: 100vh;
  overflow: hidden;
  // border: 1px solid red;
}


.main_contents {
  max-width: 1460px;
  padding-inline: 50px;
  margin-inline: auto;
  
  height: 100vh;
  
  display: flex;
  align-items: center;
}
.main_content {
  h1 {
    font-size: clamp(38px, 4vw, 6em);
  }

  p {
    font-size: clamp(20px, 1.4vw, 2em);
    word-break: keep-all;
    color:rgba(177, 177, 177, 0.8);
    margin-top: 20px;
  }
}


.direction {
  max-width: 1460px;
  height: auto;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 10em;
  padding-block-end: 20em;
  word-break: keep-all;
  transition: all 0.4s ease-in-out;

  @media(max-width: 700px) {
    padding-block-end: 10em;
  }
}

.mapStyle {
  max-width: 1460px;
  height: 500px;
  margin-top: 40px;
  margin-bottom: 40px;
  transition: all 0.4s ease-in-out;

  @media(max-width: 900px) {
    height: 400px;
  }

  @media(max-width: 700px) {
    height: 300px;
  }

  @media(max-width: 500px) {
    height: 250px;
  }
}

.address_section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  word-break: keep-all;
  height: auto;
  width: 100%;

  h3 {
    font-size: clamp(20px, 1.5vw, 1.5em);
    margin-bottom: 20px;
  }

  p {
    margin-top: 0px;
    font-size: clamp(16px, 1.2vw, 1.2em);
  }
}

.address {
  display: flex;
  flex-direction: row;
}
/* Font */
@import url('assets/Fonts/aggro.css');
@import url('assets/Fonts/sCoreDream.css');
@import url('assets/Fonts/notoSansKR.css');

/* Color Palette */
$mainColor: #283c59;
$subColor: rgba(128, 127, 127, 0.8);
$hoverColor: rgb(255, 181, 69);

// R&D
$Medical : rgba(101, 187, 226, 0.4);
$Public : rgba(226, 147, 101, 0.4);
$Manufacturing : rgba(201, 101, 226, 0.4);


:root {
  color: $mainColor
}

.title {
  word-break: keep-all;

  h1 {
    font-size: clamp(30px, 3vw, 4em);
  }
  p {
    color: $subColor;
    margin-top: 20px;
    font-size: clamp(18px, 1.1vw, 3em);
    line-height: 1.54;
  }
}

/**Styling scrollable elements*/
.js-scroll {
  opacity: 0;
  transition: opacity 500ms;
}

.js-scroll.scrolled {
  opacity: 1;
}

.scrolled.fade-in {
  animation: fade-in .6s ease-in-out both;
}
.scrolled.fade-in-delay {
  animation: fade-in 1.2s ease-in-out both;
}

.scrolled.fade-in-bottom {
  animation: fade-in-bottom .6s ease-in-out both;
}

.scrolled.slide-left {
  animation: slide-in-left .6s ease-in-out both;
}

.scrolled.slide-right {
  animation: slide-in-right .6s ease-in-out both;
}

.scrolled.slide-top {
  animation: slide-in-top .6s ease-in-out both;
}

.scrolled.slide-bottom {
  animation: slide-in-bottom .6s ease-in-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-2-11 23:32:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.d-flex {
  display: flex;
}

.d-block {
  display: block !important;
}

.rotate_180 {
  transform: rotate(180deg);
  transition: all 0.4s ease !important;
}



.is_Active {
  color: #3b8edb !important;
  background-color: #e1ecfc;
}

.rounded-lg {
  border-radius: 12px;
}

.red {
  background-color: red;
}
.green {
  background-color: green;
}
.blue {
  background-color: blue;
}
.orange {
  background-color: orange;
}


p, h1 {
  margin: 0;
}
a {
  color: white;
  text-decoration: none;
}


body {
  position: relative;
  min-width: 390px;
  overflow-x: hidden;
  margin: 0;
  font-family: 'NotoSansKR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  -ms-overflow-style: none; /* 인터넷 익스플로러 스크롤바 없애기 */  
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
    /* 크롬, 사파리, 오페라, 엣지 스크롤바 없애기 */
  }
}


// R&D
.medi {
  background-color: $Medical;
}
.publ {
  background-color: $Public;
}
.menu {
  background-color: $Manufacturing;
}
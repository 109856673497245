@font-face {
	font-family: 'NotoSansKR';
	font-style: normal;
	font-weight: 100;
	src: url(./notosanskr/NotoSansKR-Thin.woff2) format('woff2'), 
        url(./notosanskr/NotoSansKR-Thin.woff) format('woff'), 
        url(./notosanskr/NotoSansKR-Thin.otf) format('opentype');
}

@font-face {
	font-family: 'NotoSansKR';
	font-style: normal;
	font-weight: 300;
	src: url(./notosanskr/NotoSansKR-Light.woff2) format('woff2'), 
        url(./notosanskr/NotoSansKR-Light.woff) format('woff'), 
        url(./notosanskr/NotoSansKR-Light.otf) format('opentype');
}

@font-face {
	font-family: 'NotoSansKR';
	font-style: normal;
	font-weight: 400;
	src: url(./notosanskr/NotoSansKR-Regular.woff2) format('woff2'), 
        url(./notosanskr/NotoSansKR-Regular.woff) format('woff'), 
        url(./notosanskr/NotoSansKR-Regular.otf) format('opentype');
}

@font-face {
	font-family: 'NotoSansKR';
	font-style: normal;
	font-weight: 500;
	src: url(./notosanskr/NotoSansKR-Medium.woff2) format('woff2'), 
        url(./notosanskr/NotoSansKR-Medium.woff) format('woff'), 
        url(./notosanskr/NotoSansKR-Medium.otf) format('opentype');
}

@font-face {
	font-family: 'NotoSansKR';
	font-style: normal;
	font-weight: 700;
	src: url(./notosanskr/NotoSansKR-Bold.woff2) format('woff2'), 
        url(./notosanskr/NotoSansKR-Bold.woff) format('woff'), 
        url(./notosanskr/NotoSansKR-Bold.otf) format('opentype');
}

@font-face {
	font-family: 'NotoSansKR';
	font-style: normal;
	font-weight: 900;
	src: url(./notosanskr/NotoSansKR-Black.woff2) format('woff2'), 
        url(./notosanskr/NotoSansKR-Black.woff) format('woff'), 
        url(./notosanskr/NotoSansKR-Black.otf) format('opentype');
}
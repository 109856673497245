.article_section {
  // background-color: rgba(116, 245, 185, 0.5);
  width: 75%;
  margin: 10px;
  padding: 0px 50px;
  position: relative;
  
  @media (max-width: 990px) {
    width: 100%;
    margin: 0;
    padding-inline: 0;
  };
}

.article_title {
  h1 {
    font-size: 24px;
    margin-bottom: 34px;
  }
}
$Medical : rgba(101, 187, 226, 0.4);
$Public : rgba(226, 147, 101, 0.4);
$Manufacturing : rgba(201, 101, 226, 0.4);

.card_wrap {
  padding: 30px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  // background-color: rgba(211, 223, 159, 0.5);
  

  &:hover {
    background-color: rgba(235, 174, 118, 0.2);;
  }

  span {
    border-radius: 5px;
    padding-inline: 4px;
    padding-block: 2px;
    font-weight: 600;
  }

  > p {
    margin-block: 20px;
    font-size: 24px;
    word-break: keep-all;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 98%;    
    background-color: #e6e6e6;
  }

}

.right {
  margin-top: 50px;
  font-size: 15px;
  div p {
    margin-block: 3px;
  };
  div P:first-child {
    word-break: keep-all;
    width: 150px;
  }
  div p:last-child {
    color: grey;
    font-weight: 200;
  }

  @media (max-width: 990px) {
    div {
      display: block;

      p:first-child {
        margin-bottom: 10px;
      }
      p:last-child {
        margin-bottom: 30px;
      }
    }
  }
}




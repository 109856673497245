.nav_info {
  display: flex;
  align-items: center;
}

.nav_title {
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav_wrap {
  min-height: 30px;
  max-height: 30px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;
  padding-block: 20px;
  a {
    ::before {
      content:'';
      visibility: hidden;      
    }
  }
  button {
    ::before {
      content:'';
      visibility: hidden;      
    }
  }
  ::before {
    content:'';
    position: absolute;
    bottom: 0;
    left:0;
    right: 0;
    height: 1.5px; 
    background-color: #d3d4d8;    
  }
}
.section {
  color: white;
  height: 50vh;
  background-color: #081d52;
  background-image: linear-gradient(
    76deg, #081d52 17%, 
    rgba(15, 42, 66, 0.5) 52%, 
    #061c30 70%
  );
}
.section_main_wrap {
  height: inherit;
  position: relative;
}

.main_contents {
  width: 100%;
  position: absolute;
  top: 55%;
  // top: 50%;
  // transform: translate(0, -50%);
}

.main_content {
  max-width: 1460px;
  padding-inline: 50px;
  margin-inline: auto;
  

  h1 {
    // font-size: clamp(38px, 4vw, 6em);
    font-size: clamp(25px, 3vw, 4.5em);
    word-break: keep-all;
  }
}

$hamberger_line_width: 40px;
$hamberger_line_height: 2px;
$hamberger_short_length: 24px;
$hoverColor: rgb(255, 181, 69);

.hamburger {
  // background-color: rgba(138, 171, 18, 0.6);
  position: absolute;
  right: 15px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 110;

  @media (min-width: 1845px) {
    right: calc((100% - 1800px)/2) !important;
  }
}
.active {
  span {
    background-color: rgba(0, 0, 0, 0);

    &::after {
      transition: .3s ease;
      transform: translateY(-10px) rotateZ(135deg);
    }
    &::before {
      transition: .3s ease;
      transform: translateY(10px) rotateZ(-135deg);
    }
  }

  @media(max-width: 990px) {
    span {
      &::after {
        transform: translateY(-7px) rotateZ(135deg);
      }
      &::before {
        transform: translateY(7px) rotateZ(-135deg);
      }
    }
  }
}
.hamburger_wrap {
  // background-color: rgba(255, 171, 18, 0.6);
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media(max-width: 990px) {
    // background-color: rgba(255, 171, 18, 0.6);
    width: $hamberger_short_length;
    height: $hamberger_short_length;
  }
}
.line {
  width: $hamberger_line_width;
  height: $hamberger_line_height;
  background-color: white;
  border-radius: 100px;
  margin-block: 4px;
  position: relative;
  transition: .3s ease;

  &::before, &::after {
    content: " ";
    position: absolute;
    width: $hamberger_line_width;
    height: $hamberger_line_height;
    background-color: white;
    border-radius: 100px;
    transition: .3s ease;
  }
  &::before {
    top: -10px
  }
  &::after {
    top: 10px
  }
  @media(max-width: 990px) {
    &::before, &::after {
      width: $hamberger_short_length;
    }
    &::before {
      top: -7px ;
    }
    &::after {
      top: 7px ;
    }
  }
}


.hamberger_menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(4px);
  background: linear-gradient(180deg, rgba(0,20,39,0.9) 0%, rgba(0,38,80,0.9) 100%);
  transition: all .4s ease;
  z-index: 105;
  opacity: 0;
  visibility: hidden;
  overflow-y: hidden;
}
.open {
  opacity: 1;
  visibility: visible;
}
.hamberger_menu_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: calc(100% - 60px);
  margin: 0 auto;
  padding: 30px 16px;
  overflow-y: auto;
  // background-color: rgba(255, 45, 81, 0.5);

  @media(max-width: 990px) {
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 60px;
    width: calc(100% - 32px);
    height: calc(100% - 120px);
  }
}

.hamberger_menu_list_wrap {
  list-style: none;
  display: flex;
  padding-left: 0;

  @media(max-width: 990px) {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
  }
}
.hamberger_menu_list {
  padding-inline: 2.3vw;
  text-align: center;

  a {    
    font-weight: 600;
    font-size: clamp(30px, 2.5vw, 38px);
  }
  svg {
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;
  }
  @media(max-width: 990px) {
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding: 0;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 55px;
      font-size: 19px;
      font-weight: 500;
      line-height: 1.28;
    }

    svg {
      opacity: 1;
      visibility: visible;
    }
  }
}



.hamberger_sub_menu_list_wrap {
  list-style: none;
  display: block;
  padding-left: 0px;
  
  @media (max-width: 990px) {
    display: none;
  }
}
.hamberger_sub_menu_list {
  min-height: 46px;
  color: #fff;
  text-align: center;

  a {
    height: 50px;
    font-size: 20px;
    font-weight: 400;
    p {
      color: rgba(255, 255, 255, 0.7);
    }
    &:hover {
      p {        
        transform: scale(1.1);
        transition: transform 0.1s ease;
        transform-origin: center;
      }
    }
  }

  @media(max-width: 990px) {
    padding-left: 20px;
    a {
      &:hover {
        p {
          transform-origin: left;
        }
      }

      p {
        color: rgba(255, 255, 255, 0.8);
        font-size: 15px;
      }
    }
  }
}


.click {
  display: block;
  animation: slide-in-top .1s ease-in-out;
}
@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
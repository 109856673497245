.section_wrap_row {
  max-width: 1460px;
  display: flex;
  align-items: center;
  margin-inline: auto;
  padding: 20em 50px 10em 50px;
  z-index: 15;
}
.purpose_contents {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;

  @media(max-width: 1200px) {
    flex-direction: column;
  }
}
.purpose_title {
  width: 45%;
  margin-block: auto;

  @media(max-width: 1200px) {
    width: 100%;
  }
}
.sub_p {
  p {
    font-size: clamp(17px, 1.05vw, 3em);
    font-weight: 400 !important;
    line-height: 1.54;
    color: rgba(128, 127, 127, 0.8);
    word-break: keep-all;
  }
}


.purpose_image {
  width: 55%;
  // display: flex;

  @media(max-width: 1200px) {
    width: 100%;
    margin-top: 100px;
  }
}

// .parallax {
//   width: 100%;
//   border-radius: 10px;
//   background-size: cover;
//   background-position: center;
//   background-attachment: fixed;
//   background-repeat: no-repeat;
//   background-image: url('../../../../assets/Images/Humandeep/lendingPageImg/purpose_brain.png');
// }

.img {
  width: 100%;
  // background-color: rgba(234, 235, 241, 0.1)
}
// .purpose_bg {
//   width: 100%;
//   height: 120%;
//   position: absolute;
//   left: 10em;
//   // background-color: rgba(208, 217, 223, 0.5);
//   margin-right: -550px;
//   background-color: rgba(0, 255, 0, 0.2);  
//   border-radius: 10px 0px 0px 10px;
// }
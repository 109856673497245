


@media (max-width: 768px) {
  #wrap > img {
    padding-top: 100px;
    object-fit: cover;
    width: 100% !important;
  }

  .title {    
    padding-top: 20% !important;
    padding-bottom: 30% !important;
  }
}

.title h1 {
  font-size: 2.8em;
}
.title {
  text-align: center;
  color: #283c59;
  padding-top: 5%;
  padding-bottom: 10%
}

#wrap > img {
  display: block;
  margin: auto;
  object-fit: contain;
  aspect-ratio: 2;
  width: 90%;
}

.bg {
  color: white;
  height: 100vh;
  background-color: #081d52;
  background-image: linear-gradient(76deg, #081d52 17%, rgba(13, 36, 59, 0.5) 52%, #061e35 79%);
  overflow: hidden;
  display: flex;
}
.category {
  width: 35vw;
  height: inherit;
  // background-color: rgba(118, 138, 93, 0.4);
  position: relative;
  background-image: linear-gradient(76deg, #455275 17%, rgba(68, 92, 116, 0.5) 52%, #1d3b57 79%);;
}

.division {
  width: 46%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background-color: rgba(118, 138, 93, 0.4);
  z-index: 20;

  div {
    height: 100px ;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



.division_items {
  list-style: none;
  padding-left: 0;
  position: absolute;
  top:0;
  left: 0;
  width: calc(35vw * 0.46);
  height: calc(100vh - 200px);
  z-index: 30;
  margin-block: 100px;
  // background-color: rgba(0, 220, 210, 0.2);
  &::before {
    content: '';
    position: absolute;
    left: calc(35vw * 0.46);
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: rgba(119, 145, 201, 0.3);

  }

  li {
    font-size: clamp(16px, 1vw, 20px);
    margin: 10px 20px;
    padding: 0 10px;
    border-radius: 10px;
    min-height: calc(((100vh - 200px) - 100px) * (1 / 9));
    background-color: rgba(119, 145, 201, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: keep-all;
    text-align: center;
    
    &:hover {
      cursor: pointer;
      background-color: rgba(170, 187, 224, 0.4);
      transition: all 0.7s ease;
    }
  }
}

.active {
  background-color: rgba(170, 187, 224, 0.4) !important;
}

.sub_division {
  // background-color: rgba(233, 164, 61, 0.3);
  margin-block: 100px;
  width: calc(35vw * 0.54);
  
  ul {
    list-style: none;
    padding-left: 0;
    margin-block: 0;


    li {
      font-size: 16px;
      margin: 10px 20px;
      padding: 0 10px;
      border-radius: 10px;
      min-height: calc(((100vh - 200px) - 100px) * (1 / 9));
      background-color: rgba(119, 145, 201, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      word-break: keep-all;
      text-align: center;

      &:hover {
        cursor: pointer;
        background-color: rgba(170, 187, 224, 0.4);
        transition: all 0.7s ease;
      }
    }
  }
}



.contents {
  width: 65vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content {
  font-size: clamp(36px, 4vw, 4em);
}

.back_p {
  text-align: end;
  margin-top: 50px;
  font-size: 16px;
  cursor: pointer;
  color: grey;

  &:hover {
    color: rgb(255, 181, 69);
  }

}
.footer {
  width: 100%;
  background-color: #303131;
  color: white;
}

.footer_section_notice1 {
  max-width: 1460px;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 4em;
  border-bottom: 1px solid #e6e6e6;
  @media (max-width: 990px) {
    padding-block: 2em;
  }
}

.footer_section_inner {
  margin: 0 auto;

  p {
    font-size: clamp(30px, 2.4vw, 40px);
    font-weight: 600;
    padding: 16px;
    margin: 0;
  }
}

.footer_section_list {
  margin-block: 2em;
  padding-top: 35px;
  padding-bottom: 45px;
  word-break: keep-all;
  margin: 0;
  @media (max-width: 990px) {
    padding-top: 25px;
    padding-bottom: 35px;    
  }
}

.notice_item {
  font-size: clamp(18px, 1.5vw, 20px);
  line-height: 35px;
  padding-right: 20px;
}


.footer_section_notice2 {
  max-width: 1460px;
  padding-inline: 50px;
  margin-inline: auto;
  padding-block: 1em;
}

.footer_inner {
  display: flex;
  align-items: center;
  padding-block: 16px;
  position: relative;
  margin: 0 auto;
  height: 180px;

  @media (max-width: 990px) {
    flex-direction: column;
    justify-content: center;
    padding-block: 5px;
    max-width: 1100px;

    div {
      padding-block: 20px;
      position: relative;
      margin: 0 auto;
      max-width: 1100px;
      height: 180px;
    }
  }
}


.footer_inner_title {
  display: flex;
  align-items: center;

  a {
    font-size: clamp(30px, 2.4vw, 40px);
    font-weight: 600;
    padding-left: 10px;
  }

  img {
    margin-left: 16px;
    width: 40px
  }

  @media (max-width: 990px) {
    width: 100%;
  }
}

.gradient_text {
  background: linear-gradient(to right, #3e84d4, #74a0e2);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer_inner_info {
  list-style: "@ ";
  margin-left: auto;
  color: gray;
  padding-left: 0;

  li {
    margin: 0;
    
  }

  @media (max-width: 990px) {
    width: 100%;

    li {
      display: block;
      padding-inline: 20px;
    }
  }
}
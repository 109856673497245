.navbar {
  // background-color: rgba(255, 162, 255, 0.4);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;  // 평상시 nav 높이
  transition: all .4s;
  z-index: 100;

  @media (max-width: 990px) {
    // background-color: rgba(255, 162, 255, 0.4);
    transition: all .4s;
    height: 60px !important;
  }
}
.scroll {
  border-radius: 0 0 5px 5px;
  height: 68px;
  backdrop-filter: blur(7px);
  background-color: rgba(6, 45, 81, 0.5) ;
  @media (max-width: 990px) {
    border-radius: 0 0 5px 5px;
    backdrop-filter: blur(7px);
    background-color: rgba(6, 45, 81, 0.5) ;
  }
}
.navbar_wrap {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0 15px;
  height: inherit;
}
.custom_slide {
  height: 350px;
}
.slide_img {
  width: 100%;
  display: block;
  margin: auto;
}
@media (max-width: 768px) {
  .slide_img {
    max-width: 350px;
  }
}

.content {
  display: flex;
  padding: 16px;
}

#Labs > p {
  font-size: 1.1em;
  padding-inline: 16px;
}

#Labs > h1 {
  font-size: 2.1em;
  margin-block: 0;
  padding: 16px 16px 0 16px;
  
}

#Labs {
  position: relative;
  margin: 0 auto;
  max-width: 1100px;
  // text-align: center;
  padding-block: 116px;
  color: black;
}

#Labs::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #e6e6e6;
}